'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = createCycleMiddleware;

var _xstream = require('xstream');

var _xstream2 = _interopRequireDefault(_xstream);

var _adapt = require('@cycle/run/lib/adapt');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function createCycleMiddleware() {
  var store = null;
  var actionListener = null;
  var stateListener = null;

  var cycleMiddleware = function cycleMiddleware(_store) {
    store = _store;
    return function (next) {
      return function (action) {
        var result = next(action);
        if (actionListener) {
          actionListener.next(action);
        }
        if (stateListener) {
          stateListener.next(store.getState());
        }
        return result;
      };
    };
  };

  cycleMiddleware.makeActionDriver = function () {
    return function actionDriver(outgoing$) {
      outgoing$.addListener({
        next: function next(outgoing) {
          if (store) {
            store.dispatch(outgoing);
          }
        },
        error: function error() {},
        complete: function complete() {}
      });

      return (0, _adapt.adapt)(_xstream2.default.create({
        start: function start(listener) {
          actionListener = listener;
        },
        stop: function stop() {}
      }));
    };
  };

  cycleMiddleware.makeStateDriver = function () {
    var isSame = {};
    return function stateDriver() {
      var getCurrent = store.getState;
      return (0, _adapt.adapt)(_xstream2.default.create({
        start: function start(listener) {
          stateListener = listener;
        },
        stop: function stop() {}
      }).fold(function (prevState, currState) {
        if (prevState === getCurrent) {
          prevState = getCurrent();
        }
        if (prevState === currState) {
          return isSame;
        }
        return currState;
      }, getCurrent).map(function (state) {
        return state === getCurrent ? getCurrent() : state;
      }).filter(function (state) {
        return state !== isSame;
      }));
    };
  };

  return cycleMiddleware;
}