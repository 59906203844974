'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = combineCycles;

var _xstream = require('xstream');

var _xstream2 = _interopRequireDefault(_xstream);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function combineCycles() {
  for (var _len = arguments.length, mains = Array(_len), _key = 0; _key < _len; _key++) {
    mains[_key] = arguments[_key];
  }

  return function (sources) {
    var sinks = mains.map(function (main) {
      return main(sources);
    });

    var drivers = Object.keys(sinks.reduce(function (drivers, sink) {
      return Object.assign(drivers, sink);
    }, {}));

    var combinedSinks = drivers.reduce(function (combinedSinks, driver) {
      var driverSinks = sinks.filter(function (sink) {
        return sink[driver];
      }).map(function (sink) {
        return _xstream2.default.from(sink[driver]);
      });

      combinedSinks[driver] = _xstream2.default.merge.apply(_xstream2.default, _toConsumableArray(driverSinks));
      return combinedSinks;
    }, {});

    return combinedSinks;
  };
}